import { CSSProperties, ReactElement, ReactNode } from 'react';
import { merge } from 'lodash';
import {
  backdropClasses,
  Dialog,
  DialogActions,
  dialogActionsClasses,
  dialogClasses,
  DialogContent,
  dialogContentClasses,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  dialogTitleClasses,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';

import { MPFonts, MPIconButton } from '../..';
import useDefaultTheme from '../hooks/useDefaultTheme';
import { BackIcon, RemoveIcon } from '../icons';

export interface MPDialogProps extends DialogProps {
  /**
   * Contents of the Dialog
   */
  children: ReactElement<any, any> | ReactNode;

  /**
   * If true, the component is shown.
   */
  open: boolean;

  /**
   * The text that indicates what this dialog is for
   */
  title: string;

  /**
   *  Button or list of buttons indicating actions for the dialog
   */
  actionButton?: ReactElement | Array<ReactElement>;

  /**
   * Classes applied to the dialog content
   */
  contentClasses?: DialogContentProps['classes'];

  /**
   * Function that is called when the components wants to close.
   */
  onClose?: (
    event: Record<string, unknown>,
    reason: 'closeIconClick' | 'backdropClick' | 'escapeKeyDown'
  ) => void;

  /**
   * Function that is called when the prefix button is clicked
   */
  onPrefixClick?: () => void;

  /**
   * The system prop that allows defining system overrides as well as additional CSS styles
   */
  sx?: SxProps<Theme>;

  /**
   * The style of the title
   */
  titleSx?: CSSProperties;
}

const prefixPlaceholderStyle: CSSProperties = {
  width: 32,
};

const titleStyle: CSSProperties = {
  flexBasis: 0,
  flexGrow: 1,
  textAlign: 'center',
};
const baseActionsStyle: CSSProperties = {
  borderTop: '1px solid var(--color-primaryP5)',
  flexDirection: 'column-reverse',
  gap: '16px',
};

export default function MPDialog({
  title,
  sx,
  titleSx,
  contentClasses,
  open,
  onClose,
  actionButton,
  children,
  onPrefixClick,
  ...passedProps
}: MPDialogProps) {
  const isMobile = useMediaQuery(useDefaultTheme().breakpoints.down('desktop'));
  const mergedSx = merge(
    {
      [`& .${backdropClasses.root}`]: {
        background: MPColorValue.BackgroundOverlay,
      },
      [`& .${dialogClasses.paper}`]: {
        borderRadius: '0px',
        boxShadow: '0px 9px 28px 18px rgba(0, 0, 0, 0.04);',
        maxHeight: isMobile ? '100%' : '90vh',
        width: isMobile ? '100%' : '90vw',
      },
      [`& .${dialogContentClasses.root}`]: {
        padding: 0,
      },
      [`& .${dialogTitleClasses.root}`]: {
        alignItems: 'center',
        borderBottomColor: MPColorValue.BackgroundDefault,
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        boxSizing: 'content-box',
        display: 'flex',
        gap: '10px',
        height: '32px',
        padding: isMobile ? '24px 24px' : '24px 34px',
      },
      [`& .${dialogActionsClasses.root}>:not(:first-of-type)`]: {
        margin: 0,
      },
      [`& .${dialogActionsClasses.root}>button`]: {
        width: '100%',
      },
    },
    sx ?? {}
  );
  const mergedTitleSx = merge({}, titleStyle, titleSx ?? {});

  const actionsStyle = merge({}, baseActionsStyle, {
    padding: isMobile ? '16px 24px 32px' : '16px 34px 32px',
  });

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      sx={mergedSx}
      onClose={onClose}
      {...passedProps}
    >
      <DialogTitle>
        {onPrefixClick ? (
          <MPIconButton onClick={onPrefixClick}>
            <BackIcon />
          </MPIconButton>
        ) : (
          <span style={prefixPlaceholderStyle} />
        )}
        <span className={MPFonts.headline4} style={mergedTitleSx}>
          {title}
        </span>
        {onClose ? (
          <MPIconButton onClick={() => onClose({}, 'closeIconClick')}>
            <RemoveIcon alt="Close Dialog" />
          </MPIconButton>
        ) : (
          <span style={prefixPlaceholderStyle} />
        )}
      </DialogTitle>
      <DialogContent classes={contentClasses}>{children}</DialogContent>
      {!!actionButton && (
        <DialogActions sx={actionsStyle}>{actionButton}</DialogActions>
      )}
    </Dialog>
  );
}
