.container {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    background: linear-gradient(
      var(--exploreDialog-gradientDeg),
      rgba(245, 245, 245, 0) -2%,
      var(--color-backgroundDefault) 100%
    );
    content: " ";
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: calc(
      var(--exploreDialog-gradientMultiplier, 1) *
        var(--exploreDialog-horizontalPadding)
    );
    z-index: 1;
  }

  &::before {
    --exploreDialog-gradientDeg: 270deg;

    left: 0;
  }

  &::after {
    --exploreDialog-gradientDeg: 90deg;
    --exploreDialog-gradientMultiplier: 2;

    right: 0;
  }
}

.innerContainer {
  overflow-x: auto;
  width: 100%;
  z-index: -1;
}

.title {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 var(--exploreDialog-horizontalPadding) 0
    calc(
      var(--exploreDialog-horizontalPadding) + var(--exploreDialog-prefixSize)
    );
  width: max-content;
}

.tab {
  background-color: var(--color-commonWhite);
  border: none;
  border-radius: 80px;
  color: var(--color-commonBlack);
  cursor: pointer;
  padding: 6px 16px;

  @media (--mq-is-not-mobile) and (hover: hover) and (pointer: fine) {
    &:hover:not(.selected) {
      border-color: var(--color-SolidNeutralGray3);
      color: var(--color-SolidNeutralGray3);
    }
  }

  &.selected {
    background-color: var(--color-commonBlack);
    color: var(--color-commonWhite);
    cursor: default;

    @media (--mq-is-not-mobile) and (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: var(--color-commonBlack);
        background-color: var(--color-SolidNeutralGray3);
      }
    }
  }
}
