import { forwardRef, useCallback, useEffect, useRef } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import { ExploreType } from '../types';

import * as styles from 'css/pages/explore/dialog/Tabs.module.css';

interface TabProps {
  exploreType: ExploreType;
  isSelected: boolean;
  onSelect: (exploreType: ExploreType) => void;
}

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ exploreType, isSelected, onSelect }, ref) => {
    const handleClick = useCallback(
      () => onSelect(exploreType),
      [exploreType, onSelect]
    );

    return (
      <button
        ref={ref}
        className={joinClasses(MPFonts.textSmallSemiBold, styles.tab, {
          [styles.selected]: isSelected,
        })}
        type="button"
        onClick={handleClick}
      >
        {ExploreType[exploreType]}
      </button>
    );
  }
);

export interface ExploreDialogTabsProps {
  exploreType: ExploreType;
  onTypeChange: (exploreType: ExploreType) => void;
}

export default function ExploreDialogTabs({
  exploreType,
  onTypeChange,
}: ExploreDialogTabsProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: string]: HTMLButtonElement }>({});

  const scrollToPosition = (container, position) => {
    container.scrollTo({ behavior: 'smooth', left: position });
  };

  useEffect(() => {
    if (!scrollRef.current || !exploreType) return;
    const container = scrollRef.current;
    const selectedButton = tabRefs.current[exploreType];

    if (!selectedButton) return;

    const { left: containerLeft } = container.getBoundingClientRect();
    const { left: selectedTabLeft } = selectedButton.getBoundingClientRect();
    const { scrollWidth, clientWidth, scrollLeft } = container;
    const selectedXPosition = selectedTabLeft - containerLeft;
    const nearEndThreshold = 100;

    if (selectedXPosition > clientWidth - nearEndThreshold) {
      scrollToPosition(
        container,
        Math.min(
          scrollLeft + (clientWidth - nearEndThreshold),
          scrollWidth - clientWidth
        )
      );
    } else if (selectedXPosition < nearEndThreshold) {
      scrollToPosition(
        container,
        Math.max(scrollLeft - (clientWidth - nearEndThreshold), 0)
      );
    }
  }, [scrollRef, exploreType]);

  return (
    <div className={joinClasses(styles.container)}>
      <div
        className={joinClasses(CSSGlobal.NoScrollbar, styles.innerContainer)}
        ref={scrollRef}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.CenteredRow,
            CSSGlobal.Flex.NoWrap,
            CSSGap[16],
            styles.title
          )}
        >
          {[
            ExploreType.Artworks,
            ExploreType.Exhibitions,
            ExploreType.Artists,
            ExploreType.Editorial,
          ].map((key) => (
            <Tab
              key={key}
              exploreType={ExploreType[key]}
              isSelected={exploreType === ExploreType[key]}
              onSelect={onTypeChange}
              ref={(el) => {
                tabRefs.current[ExploreType[key]] = el;
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
